import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export function ResponseSendContactHeaderTwo({
  texts,
}) {
  return (
    <>
      {texts.map((text, index) => {
        return (
          <Fragment key={text}>
            {text} {index < texts.length - 1 && <br />}
          </Fragment>
        );
      })}
    </>
  );
}

ResponseSendContactHeaderTwo.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string),
};

ResponseSendContactHeaderTwo.defaultProps = {
  texts: [
    'We will get to know your idea and get back to you within 24\u00A0business hours.',
  ],
};
