export const sliderData = [
  {
    background: 'img/Traffic_Family_case_study_-_AI_based_ecommerce_platform.jpg',
    caption: 'AI services',
    info: 'case study',
    description:
      'Expanding Traffic Family\'s Online Business With AI Support',
    color: 'yellow',
    link: '/work/case-study-improving-online-business-with-ai/',
  },
  {
    background:
      'img/AI-powered_bulk_shipping_personal_assistant_app.png',
    caption: 'AI services',
    info: 'case study',
    description:
      'Empowering Users of a Building Materials Trading Platform with a Smart, AI-Powered Assistant',
    color: 'red-main',
    link: '/work/case-study-building-materials-ai-assistant/',
  },
  {
    background: 'img/proof_of_value_1.png',
    caption: 'AI services',
    info: 'case study',
    description:
      'Using an AI-Powered Proof of Concept for a Client from the Health Food Industry',
    color: 'yellow',
    link: '/blog/ai-powered-poc-health-food-industry/',
  },
  {
    background: 'img/AI_POC_knowledge_base.jpg',
    caption: 'AI services',
    info: 'case study',
    description:
      'Demonstrating Viability with Proof of Concept: An AI-Powered Chatbot Case Study',
    color: 'pigeon-post',
    link: '/work/ai-powered-chatbot-knowledge-base-case-study/',
  },
];
