import React, { useRef } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { SectionHeader, Section, Paragraph } from 'components';

import CountryTimeline from '../CountryTimeline';
import CountryDropdown from '../CountryDropdown';
import { data, contactPersonData } from './logic';

import './ContactOffices.scss';

const ContactOffices = () => {
  const imageScrollRef = useRef(null);

  return (
    <div ref={imageScrollRef}>
      <Section
        additionalClass={'contact-offices'}
        color={'violet-main'}
        spaceless
      >
        <Row className={'contact-offices__max-width'}>
          <Col md={12} lg={12} className={'contact-offices-description'}>
            <Paragraph size={'medium'} color={'white'}>
              HELLO WORLD!
            </Paragraph>
            <SectionHeader
              size={'medium'}
              headingType={2}
              additionalClass={'contact-offices-heading'}
              color={'white'}
              noLine
            >
              <span>
                Let’s talk! We’re here to help,
                <span className={'contact-offices-heading__world'}>
                  {' '}
                  online or offline.
                </span>
              </span>
            </SectionHeader>
            <CountryTimeline
              data={data}
              contactPersonData={contactPersonData}
            />
            <CountryDropdown data={data} imageScrollRef={imageScrollRef} />
          </Col>
        </Row>
      </Section>
    </div>
  );
};

export default ContactOffices;
